import Vue from 'vue'

import App from './App.vue'
import router from './router'

import vuetify from '@/plugins/vuetify'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import { InlineSvgPlugin } from 'vue-inline-svg'
import draggable from "vuedraggable"


import axios from 'axios'
import VueAxios from 'vue-axios'

import './registerServiceWorker'

Vue.config.productionTip = false

/* Register global libraries & components */

Vue.use(VueAxios, axios)
Vue.use(VueTelInputVuetify, { vuetify })
Vue.use(InlineSvgPlugin)

Vue.component('draggable', draggable)

new Vue(
  {
    vuetify,
    router,
    render: h => h(App)
  }
).$mount('#app')
