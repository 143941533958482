/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(
    `${process.env.BASE_URL}service-worker.js`, {
      cached () {
        // Ensure cache is populated by reloading once after SW registration
        navigator.cached = navigator.cached
          ? navigator.cached
          : window.location.reload()
      }
    }
  )
}
