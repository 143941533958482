import Vue from 'vue'
import VueRouter from 'vue-router'

const HomeView = () => import('../views/HomeView.vue')
const RegisterView = () => import('../views/RegisterView.vue')


Vue.use(VueRouter)



var
  productionRoutes = [

    {
      path: '/',
      name: 'Home',
      component: HomeView
    },

    {
      path: '/:access/:environment/:dataset/:type/:version',
      name: 'Register',
      component: RegisterView
    },

  ],

  developmentRoutes = [
    {
      path: '/example/:schema',
      name: 'Example',
      component: RegisterView
    },

    {
      path: '/local/:access/:environment/:dataset/:type/:version',
      name: 'Local',
      component: RegisterView
    }
  ],

  routes = {
    mode: 'history',
    base: process.env.BASE_URL,
    routes: process.env.NODE_ENV == 'production'
      ? productionRoutes
      : developmentRoutes.concat(productionRoutes)
  }

const router = new VueRouter(routes)

export default router
