
<!-- -------------------------- Main App Component ------------------------- -->


<template>
  <v-app>
    <div id="app" class="he-app" :style="themeColors">
      <router-view/>
    </div>
  </v-app>
</template>


<!-- ----------------------------------------------------------------------- -->


<script>

  export default {
    computed: {
      themeColors() {
        var themeColors = {};
        Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
          themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
        });
        return themeColors;
      }
    }
  }

</script>


<style lang="scss">

  html body #app {

    align-items: center;
    background: -webkit-linear-gradient(to bottom, #ccc, #aaa);
    background: linear-gradient(to bottom, #eeeef0 75%, #dfdfe2);
    background-attachment: fixed;
    color: #000;
    text-align: left;

    min-height: 100vh;

    header{

      margin-bottom: 2em;

      h1{ font-size: 24pt; margin: 0; }
      h2{ font-size: 12pt; margin: 0; }

    }

    .theme--light.v-label,
    .theme--light.v-card,
    .theme--light.v-card > .v-card__text,
    .theme--light.v-card > .v-card__subtitle,
    .theme--light.v-select .v-select__selections,
    .theme--light.v-input input, .theme--light.v-input textarea{
      color:#000 !important;
    }
  }

  .v-tooltip__content > div{
    min-width: 50vw;
  }

</style>
